import React, {useEffect} from 'react';
import './App.scss';
import NavBar from "./components/NavBar/VaNavbar";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import FilterPage from "./components/FilterPage/FilterPage";
import Amplify, {Auth} from 'aws-amplify';
import awsmobile from './aws-exports.js'
import currentConfig from './aws-exports-configure.js'
import {LoginPage, SignUpPage} from './components/SignupPage/SignUpPage';
import {AccountCreationFlow} from './components/AccountCreation/AccountCreation'
import {selectClaims, selectHasSubscribed, selectIsLoggedIn} from "./auth/authSlice";
import {useSelector} from "react-redux";
import CompsPage from './components/CompsPage/CompsPage';
import {Routes} from "./routes/routes";
import DocumentationPage from './components/DocumentationPage/DocumentationPage';
import AboutUsPage from './components/AboutUsPage/AboutUsPage';
import AccountPage from './components/AccountPage/AccountPage';
import CompAssist from "./components/CompAssistPage/CompAssist";
import {TermsPage} from './components/TermsConditions/Terms';
import classNames from "classnames";
import {useSignOutButtonLogic} from "./components/NavBar/UseSignOutButtonLogic";
import {useFixElementOnDocumentScrollLeft} from "./components/Utility/Utility";
import {ExcelPluginDocumentationPage} from "./components/ExcelPluginDocumentation/ExcelPluginDocumentationPage";
import Privacy from "./components/TermsConditions/Privacy";
import Footer from "./components/Footer/Footer";
import HomePage from "./components/HomePage/HomePage";
import WithActiveSubscription from "./components/PadLock/WithActiveSubscription";
import ScrollToTop from "./components/Utility/ScrollToTop";
import AuthEventListener from "./components/AuthEventListener";
import SizePremiumsPage from "./components/SizePremiumsPage/SizePremiumsPage";


Auth.configure(awsmobile);
Amplify.configure(currentConfig);

function App() {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const hasSubscribedBefore = useSelector(selectHasSubscribed);
    const location = useLocation();
    const {showSignOut, setShowSignOut, handlePortraitClicked} = useSignOutButtonLogic();
    const {navRef} = useFixElementOnDocumentScrollLeft();
    const {navRef: footerRef} = useFixElementOnDocumentScrollLeft();
    const claims = useSelector(selectClaims);

    useEffect(() => {
        if (isLoggedIn) {
            setShowSignOut(false);
        }
    }, [location, isLoggedIn, claims, setShowSignOut]);

    const appPageClassNames = classNames({
        'app-page': true
    });

    function getLoginPage() {
        if (!isLoggedIn) {
            return (
                <LoginPage/>
            )
        } else if (isLoggedIn && !hasSubscribedBefore) {
            return (
                <Redirect to={Routes.createAccount}/>
            )
        } else {
            return (
                <Redirect to={Routes.compAssist}/>
            )
        }
    }

    return (
        <main className={'app'}>
            <AuthEventListener/>
            <div className={'app-nav'} ref={navRef}>
                <NavBar showSignOut={showSignOut} handlePortraitClicked={handlePortraitClicked}/>
            </div>
            <div className={appPageClassNames}>
                <ScrollToTop>
                    <Switch>
                        <Route exact path={Routes.filtering}>
                            <WithActiveSubscription fullscreen={true}>
                                <FilterPage/>
                            </WithActiveSubscription>
                        </Route>
                        <Route exact path={Routes.comps}>
                            <WithActiveSubscription fullscreen={true}>
                                <CompsPage/>
                            </WithActiveSubscription>
                        </Route>
                        <Route exact path={Routes.compAssist}>
                            <WithActiveSubscription fullscreen={true}>
                                <CompAssist/>
                            </WithActiveSubscription>
                        </Route>
                        <Route exact path={Routes.documentation}>
                            <DocumentationPage/>
                        </Route>
                        <Route exact path={Routes.excelDocumentation}>
                            <ExcelPluginDocumentationPage/>
                        </Route>
                        <Route exact path={Object.values(Routes.sizePremiums)}>
                            <SizePremiumsPage/>
                        </Route>
                        <Route exact path={Routes.signUp}>
                            <SignUpPage/>
                        </Route>
                        <Route exact path={Routes.createAccount}>
                            {
                                isLoggedIn && hasSubscribedBefore ?
                                    <Redirect to={Routes.compAssist}></Redirect> :
                                    <AccountCreationFlow
                                        isLoggedIn={isLoggedIn}
                                        isUpgrading={false}
                                    />
                            }
                        </Route>
                        {/*must be in trialing phase to get to this route*/}
                        <Route exact path={Routes.upgradeAccount}>
                            {
                                isLoggedIn && !claims.is_trialing ?
                                    <Redirect to={Routes.compAssist}></Redirect> :
                                    <AccountCreationFlow
                                        isLoggedIn={isLoggedIn}
                                        isUpgrading={true}
                                    />
                            }
                        </Route>
                        <Route path={Routes.account}>
                            <AccountPage/>
                        </Route>
                        <Route path={Routes.terms}>
                            <TermsPage/>
                        </Route>
                        <Route path={Routes.privacy}>
                            <Privacy/>
                        </Route>
                        <Route path={Routes.login}>
                            {
                                getLoginPage()
                            }
                        </Route>
                        <Route path={Routes.homePage}>
                            <HomePage></HomePage>
                        </Route>
                        <Route path={"*"}>
                            <Redirect to={Routes.homePage}/>
                        </Route>
                    </Switch>
                </ScrollToTop>
            </div>
            <div className={'footer-wrapper'} ref={footerRef}>
                <Footer></Footer>
            </div>
        </main>
    );
}

export default App;
